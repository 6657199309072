.confirmation .title {
  font-size: 1.25rem;
  font-weight: var(--semibold);
}

.confirmation button {
  font-size: 1.125rem;
  padding: 0.875rem 1.875rem;
  margin-block: 1.875rem;
  border-radius: 10px;
}

.confirmation .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
